import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { useDispatch, useSelector } from "react-redux";
import { Form, Col, Row, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import {
  getActivePaymentSetting,
  manuallyPayment,
} from "../../reducers/commonReducer";

const schema = yup.object().shape({
  paymentSetting: yup.string().required(),
  paymentimage: yup.mixed().required("Payment Image is required."),
});

const OfflinePayment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const transaction_id = queryParameters.get("transaction_id");
  const [upiType, setUpiType] = useState("UPI");
  const [copiedText, setCopiedText] = useState(null);
  const [paymentSettingId, setPaymentSettingId] = useState(localStorage.getItem("paymentSetting") || null);
  const [copyMessage, setCopyMessage] = useState(""); // State for the copy message
  const [paymentImg, setPaymentImg] = useState();
  
  useEffect(() => {
    dispatch(getActivePaymentSetting());
  }, [dispatch]);

  const {
    activeBankPaymentSettings,
    activeUpiPaymentSettings,
    activeWalletPaymentSettings,
  } = useSelector((state) => state.commonReducer);
  useEffect(() => {
    if (activeUpiPaymentSettings?.length > 0) {
      setUpiType("UPI");
    } else if (activeBankPaymentSettings?.length > 0) {
      setUpiType("BANK");
    } else if (activeWalletPaymentSettings?.length > 0) {
      setUpiType("WALLET");
    } else setUpiType(null);
  }, [
    activeBankPaymentSettings,
    activeUpiPaymentSettings,
    activeWalletPaymentSettings,
  ]);
  // text copy
  const handleCopy = (text, paymentSetting) => {
    navigator.clipboard.writeText(text);
    localStorage.setItem("paymentSetting", paymentSetting);
    setPaymentSettingId(paymentSetting)
    setCopiedText(text);
    setCopyMessage("Copied!");
    setTimeout(() => setCopyMessage(""), 2000);
  };
  return (
    <>
      {upiType === null ? (
        <div className="payment-container mid-of-the-screen">
          <h1 className="text-center text-white">
            For Pay Manually Please contact to admin.
          </h1>
        </div>
      ) : (
        <>
          <div className="yellow-bg app-font-family">
            <Col md={12}>
              <h1 className="text-center text-white mt-0 mb-0">
                Payment Method
              </h1>
            </Col>
            <Col md={12} className="mb-0">
              <p className="mb-0 text-center text-white">
                Pay Manually (PAY INR)
              </p>
            </Col>
          </div>
          <div className="payment-container">
            <Row>
              <Nav
                fill
                variant="tabs"
                defaultActiveKey={upiType}
                className="custom-tabs p-0"
              >
                {activeUpiPaymentSettings?.length > 0 && (
                  <Col>
                    <Nav.Item>
                      <Nav.Link
                        eventKey={"UPI"}
                        className="custom-tab-link app-font-family"
                        onClick={() => {
                          setUpiType("UPI");
                        }}
                      >
                        <b>UPI</b>
                      </Nav.Link>
                    </Nav.Item>
                  </Col>
                )}
                {activeBankPaymentSettings?.length > 0 && (
                  <Col>
                    <Nav.Item>
                      <Nav.Link
                        eventKey={"BANK"}
                        className="custom-tab-link app-font-family"
                        onClick={() => {
                          setUpiType("BANK");
                        }}
                      >
                        <b>BANK</b>
                      </Nav.Link>
                    </Nav.Item>
                  </Col>
                )}
                {activeWalletPaymentSettings?.length > 0 && (
                  <Col>
                    <Nav.Item>
                      <Nav.Link
                        eventKey={"WALLET"}
                        onClick={() => {
                          setUpiType("WALLET");
                        }}
                        className="custom-tab-link app-font-family"
                      >
                        <b>WALLET</b>
                      </Nav.Link>
                    </Nav.Item>
                  </Col>
                )}
              </Nav>
            </Row>
            <Row className="mt-1">
              {!!activeUpiPaymentSettings &&
                upiType === "UPI" &&
                activeUpiPaymentSettings.map((item, index) => (
                  <Col md={4} sm={6} xs={12} key={index} className="p-0">
                    <div className="payment-card">
                      {upiType === "UPI" && (
                        <p className="mb-1 app-font-family">
                          <b>UPI:</b> {item?.upi}{" "}
                          <i
                            onClick={() => handleCopy(item?.upi, item.id)}
                            className={`clipboard-icon bi bi-clipboard ${
                              copiedText === item?.upi ? "copied" : ""
                            }`}
                          ></i>
                        </p>
                      )}
                    </div>
                  </Col>
                ))}
              {!!activeBankPaymentSettings &&
                upiType === "BANK" &&
                activeBankPaymentSettings.map((item, index) => {
                  return (
                    <Col md={4} sm={6} xs={12} key={index} className="p-0">
                      <div className="payment-card">
                        <p className="mb-1 app-font-family">
                          <b>Bank Holder:</b> {item?.bankHolder}
                          <i
                            onClick={() =>
                              handleCopy(item?.bankHolder, item.id)
                            }
                            className={`clipboard-icon bi bi-clipboard ${
                              copiedText === item?.bankHolder ? "copied" : ""
                            }`}
                          ></i>
                        </p>
                        <p className="mb-1 app-font-family">
                          <b>Bank Account:</b> {item?.bankAccount}
                          <i
                            onClick={() =>
                              handleCopy(item?.bankAccount, item.id)
                            }
                            className={`clipboard-icon bi bi-clipboard ${
                              copiedText === item?.bankAccount ? "copied" : ""
                            }`}
                          ></i>
                        </p>
                        <p className="mb-1 app-font-family">
                          <b>IFSC Code:</b> {item?.bankIfsc}
                          <i
                            onClick={() => handleCopy(item?.bankIfsc, item.id)}
                            className={`clipboard-icon bi bi-clipboard ${
                              copiedText === item?.bankIfsc ? "copied" : ""
                            }`}
                          ></i>
                        </p>
                      </div>
                    </Col>
                  );
                })}
              {!!activeWalletPaymentSettings &&
                upiType === "WALLET" &&
                activeWalletPaymentSettings.map((item, index) => {
                  return (
                    <Col md={4} sm={6} xs={12} key={index} className="p-0">
                      <div className="payment-card">
                        <p className="mb-1 app-font-family">
                          <b>{item?.walletType}:</b> {item?.walletPhone}{" "}
                          <i
                            onClick={() =>
                              handleCopy(item?.walletPhone, item.id)
                            }
                            className={`clipboard-icon bi bi-clipboard ${
                              copiedText === item?.walletPhone ? "copied" : ""
                            }`}
                          ></i>
                        </p>
                      </div>
                    </Col>
                  );
                })}
            </Row>
            {/*Upload Payment screenshort*/}
            <Formik
              enableReinitialize={true}
              validationSchema={schema}
              onSubmit={async (values) => {
                  const apiResp = await dispatch(manuallyPayment(values));
                  if (apiResp?.payload?.status) {
                    navigate(`/offline-payment-response?id=${transaction_id}`);
                  }
              }}
              initialValues={{
                paymentimage: paymentImg || '',
                paymentSetting: paymentSettingId ? paymentSettingId : localStorage.getItem("paymentSetting"),
                transaction_id,
              }}
            >
              {({
                handleSubmit,
                setFieldValue,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit} className="mt-4">
                  <Row className="mb-3">
                    <Col md={6} sm={12} className="p-3">
                      <Form.Group controlId="validationFormik04">
                        <Form.Label
                          htmlFor="myfile"
                          className="custom-label text-white app-font-family"
                        >
                          Upload Image
                        </Form.Label>
                        <div className="custom-file-upload">
                          <input
                            id="myfile"
                            type="file"
                            name="paymentimage"
                            className="custom-file-input"
                            onChange={(event) => {
                            const resumeFile = event.target.files[0];
                            if (resumeFile) {
                              if (resumeFile.size > 2 * 1024 * 1024) { // 2MB limit
                                alert("File size must be less than 2MB.");
                                return;
                              }
                              setFieldValue("paymentimage", resumeFile);
                              setPaymentImg(resumeFile);
                              }
                            }}
                          />
                          <label htmlFor="myfile" className="upload-btn">
                            Choose File
                          </label>
                          <span className="file-name app-font-family">
                            {document.getElementById("myfile")?.files[0]?.name
                              ? "Payment Screenshort File"
                              : "No file chosen"}
                          </span>
                        </div>
                        {errors.paymentimage && (
                          <div className="error-feedback text-white">
                            {errors.paymentimage}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Button onClick={() => {
                    if(!paymentSettingId){
                      alert("Please copy the details then Upload.")
                      }
                    }}
                    variant="success" type="submit" className="w-100 app-font-family">
                    Upload
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
          {copyMessage && <div className="copy-message">{copyMessage}</div>}
        </>
      )}
    </>
  );
};

export default OfflinePayment;
