import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Row, Table } from 'react-bootstrap';
import moment from 'moment';
import TambolaBorad from './TambolaBoard';
import rewards from '../../../images/rewards.jpg';
import Header from "../../../Components/Header";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import TimerCount from './TimerCount';
import { getBoats, tambolaDrawJackpotNumber, tambolaDrawNumber, tambolaGameByID, updateTambolaFutureGame } from '../../../reducers/commonReducer';
import SideBaar from '../../../Components/SideBaar';
import TambolaBookTicket from './TambolaBookTicket';


const TambolaGameView = () => {
  const inputRef = useRef(null);
  const [drawnumber, setDrawnumber] = useState();
  const [jackpot, setJackpot] = useState();
  const queryParameters = new URLSearchParams(window.location.search);
  const gameid = queryParameters.get('gameid');
  const automatic = queryParameters.get('automatic');
  const endStatus = queryParameters.get('endStatus');
  const startTime = queryParameters.get('start');
  const subadmin = queryParameters.get('userid');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(tambolaGameByID({ gameid, subadmin }));
    dispatch(getBoats())
    inputRef?.current?.focus();
    //in case of game automatic
    if ((endStatus === "0") && (startTime < new Date().toISOString())) {
      const apiResult = () => dispatch(tambolaGameByID({ gameid }))
      const interval = setInterval(apiResult, 7000)
      return () => clearInterval(interval);
    }
  }, [dispatch, gameid, automatic, endStatus, subadmin, startTime]);

  let delrNumber = 1;
  const declaredNum = async (e) => {
    e.preventDefault();
    if (delrNumber) {
      delrNumber = 0;
      await dispatch(tambolaDrawNumber({ gameHistory: gameid, number: drawnumber }));
      setDrawnumber('')
      await dispatch(tambolaGameByID({ gameid }))
      delrNumber = 1;
    }
  };

  const declaredJackpot = async (e) => (
    e.preventDefault(),
    await dispatch(tambolaDrawJackpotNumber({ gameHistory: gameid, number: +jackpot })),
    inputRef?.current?.focus(),
    await dispatch(tambolaGameByID({ gameid }))
  );

  const { tambolaGameByIdData } = useSelector((state) => state.commonReducer);
  let timeDiff = moment(startTime)?.diff(moment(), "minutes");
  return (
    <>
      <Row>
        <Col md={2} className="p-0">
          <SideBaar />
        </Col>
        <Col md={10}>
          <Header />
          <div className="mt-3">
            <Row>
              <Col md={4} className="mt-2">
                <TimerCount
                  gameTime={tambolaGameByIdData?.start}
                  endTime={tambolaGameByIdData?.end}
                  startTime={tambolaGameByIdData?.start}
                />
              </Col>
              <Col md={4}>
                <h4 className="text-center">{tambolaGameByIdData?.name}</h4>
              </Col>
              <Col md={4} className='text-align-end'>
                {
                  ((localStorage.getItem('userRole') === "1") && automatic === "true" && timeDiff > 5) && (
                    <Button
                      className="m-1 py-0 px-1"
                      variant="warning"
                      onClick={async () => {
                        const userConfirmed = window.confirm(`Are you sure the game is ${tambolaGameByIdData?.hiddenNumbers ? 'Re-Generate' : 'Generate'}?`);
                        if (userConfirmed) {
                          const apiResp = await dispatch(updateTambolaFutureGame({
                            id: tambolaGameByIdData?.id,
                            genratenumber: 1
                          }))
                          if(apiResp?.payload?.status){
                            dispatch(tambolaGameByID({ gameid, subadmin }));
                          }
                        }
                      }}
                    >
                      {tambolaGameByIdData?.hiddenNumbers?.length ? 'Re-Generate' : 'Generate'}
                    </Button>
                  )
                }
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md={6}>
                <p className="mb-0">
                  Game Id:-{' '}
                  <b>{`${tambolaGameByIdData?.idd} (${moment(tambolaGameByIdData?.start).format(
                    'MMMM Do YYYY, h:mm:ss a',
                  )})`}</b>
                </p>
              </Col>
              <Col>
                <p className="mb-0">
                  TicketPrice:- <b>{tambolaGameByIdData?.ticketPrice}</b>
                </p>
              </Col>
              <Col>
                <p className="mb-0">
                  TicketSet:- <b>{tambolaGameByIdData?.ticketSet}</b>
                </p>
              </Col>
              <Col>
                <p className="mb-0">
                  TotalBooked:- <b>{(localStorage.getItem("appSlug")?.length > 0) ? (tambolaGameByIdData?.totalBookedTicket / tambolaGameByIdData?.ticketSet) : tambolaGameByIdData?.totalBookedTicket}</b>
                </p>
              </Col>
              <Col>
                <p className="mb-0">
                  {(localStorage.getItem('userRole') === "1" && !tambolaGameByIdData?.end) && (
                    <Button
                      variant="danger"
                      onClick={async () => {
                        if (window.confirm("Are you sure to reset!")) {
                          await dispatch(updateTambolaFutureGame({
                            id: tambolaGameByIdData?.id,
                            reset: 1,
                            unsoldwinner: 0,
                            videoid: 0
                          }));
                          alert('Reset Game done with in 2 to 3 min');
                          dispatch(tambolaGameByID({ gameid }));
                        }

                      }}
                    >
                      Reset Game
                    </Button>
                  )}
                </p>
              </Col>
            </Row>
            <hr />
            <Container>
              <Row>
                <Col md={6}>
                  <TambolaBorad crossnumbers={tambolaGameByIdData} />
                  {
                    (tambolaGameByIdData?.autoMatic && (localStorage.getItem('userRole') === "1")) && <>
                      <hr className='m-0' />
                      <Col md={12} className='word-wrap-break'>
                        <p>
                          <b>Numbers:-</b> {
                            !!tambolaGameByIdData?.hiddenNumbers && tambolaGameByIdData?.hiddenNumbers?.map((number, index) => <Fragment key={index}>{number},</Fragment>)
                          }
                        </p>
                      </Col>
                      <Col md={12} className='mt-2'>
                        <b>JackPot:-</b> {tambolaGameByIdData?.hidenJackPot}
                      </Col>
                      <Col md={12} className='mt-2'>
                        <Table striped bordered size="sm">
                          <thead>
                            <tr>
                              <th>Reward</th>
                              <th>NumberBy</th>
                              <th>Winners</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!!tambolaGameByIdData?.hiddenWiners && tambolaGameByIdData?.hiddenWiners?.map((reward, rewardindex) => {
                              return (
                                <tr key={rewardindex}>
                                  <td className='p-0'>
                                    {reward?._id?.name}
                                  </td>
                                  <td className='p-0 text-center'>
                                    {reward?.numberby}
                                  </td>
                                  <td className='p-0'>
                                    {reward?.ticketids?.map((user, userinder) => <span key={userinder}><b>{user?.ticketid}-</b>{user?.userid?.name}({user?.userid?.username ? user?.userid?.username :
                                      <TambolaBookTicket subadmin={subadmin} bookticktgameId={gameid} boxids={[user?.ticketid]} btnTitle={true} />}), </span>)}
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                        {/* <b>Rewards</b> {!!tambolaGameByIdData?.hiddenWiners && tambolaGameByIdData?.hiddenWiners?.map((reward, rewardindex) => {
              return(
                <p key={rewardindex} className='mb-0'><b>{reward?._id?.name}</b> - <b>{reward?.numberby}</b> - {reward?.ticketids?.map((user, userinder) => <span key={userinder}>(<b>{user?.ticketid}-</b> {user?.userid?.name}({user?.userid?.username})), </span> )}</p>
              )
            })} */}
                      </Col>
                    </>
                  }
                </Col>
                <Col md={6} className="tambola-row-mrgn">
                  <div>
                    <Row>
                      <img src={rewards} alt={'Reward'} />
                    </Row>
                    {!!tambolaGameByIdData?.rewards &&
                      tambolaGameByIdData?.rewards?.map((data, index) => {
                        return (
                          <Fragment key={index}>
                            {
                              (!((localStorage.getItem("appSlug")?.length > 0) && (data?._id?.name === "Full Sheet" || data?._id?.name === "Half Sheet") ||
                                ((localStorage.getItem("website")?.length > 0) && (data?._id?.name === "Set Corner")))) &&
                              <>
                                <Row className="mt-1 mb-1">
                                  <Col md={6}>
                                    <div className="reward-tab">{data?._id?.name} <br /><span style={{ fontWeight: 200 }}>Number : {data?.numberby} </span></div>
                                  </Col>
                                  <Col md={6}>
                                    {data?.ticketids.map((user, userindex) => {
                                      return (
                                        <p className="mb-0" key={userindex}>
                                          <b>
                                            {user?.userid?.name
                                              ? user?.userid?.name + '(' + user?.userid?.username + ') ' + (user?.userid?.boat === true ? '- ## ' : '') + ' - TicketId:' + (parseInt(user.ticketid / tambolaGameByIdData?.ticketSet) + (user.ticketid % tambolaGameByIdData?.ticketSet !== 0 ? 1 : 0)) + ' BoxId:' + user.ticketid
                                              : 'UnSold -' + 'TicketId:' + (parseInt(user.ticketid / tambolaGameByIdData?.ticketSet) + (user.ticketid % tambolaGameByIdData?.ticketSet != 0 ? 1 : 0)) + ' BoxId:' + user.ticketid}{ }
                                          </b>
                                        </p>
                                      );
                                    })}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <hr className="mt-0 mb-0" />
                                  </Col>
                                </Row>
                              </>
                            }

                          </Fragment>
                        );
                      })}
                  </div>
                </Col>
              </Row>
            </Container>
            <Row className="tambola-row-mrgn">
              <hr />
              {!!tambolaGameByIdData?.jackpotNumber > 0 && (tambolaGameByIdData?.autoMatic !== true) && (
                <Col className="d-flex">
                  <h4 className="mb-0">
                    JackpotNumber:- <b>{tambolaGameByIdData?.jackpotNumber}</b>
                  </h4>
                </Col>
              )}

              {(!!tambolaGameByIdData?.jackpotPrice && (tambolaGameByIdData?.jackpotNumber === 0) && (tambolaGameByIdData?.autoMatic !== true) && ((localStorage.getItem("userRole") === "1") || (localStorage.getItem("userRole") === "5"))) &&
                <Col className="d-flex">
                  <form onSubmit={declaredJackpot}>
                    <InputGroup size="lg">
                      <Form.Control
                        placeholder="Enter Jackpot Number"
                        ref={inputRef}
                        aria-label="Large"
                        aria-describedby="inputGroup-sizing-sm"
                        type="jackpot"
                        value={jackpot}
                        onChange={(e) => setJackpot(e.target.value)}
                        min="1"
                        max="90"
                      />
                    </InputGroup>
                    <Button variant="warning" type="submit" hidden>
                      Submit
                    </Button>
                  </form>
                </Col>
              }
              {((!!tambolaGameByIdData?.jackpotNumber || tambolaGameByIdData?.jackpotPrice < 1) && (automatic === "false") && (startTime < new Date().toISOString())) && (
                <>
                  <h4 className="mb-0">
                    DeclaredNumber:-
                    {tambolaGameByIdData?.numbers.map((data, index) => (
                      <b key={index}>{`${data}, `}</b>
                    ))}
                  </h4>
                  {!tambolaGameByIdData?.end && <form onSubmit={declaredNum}>
                    <InputGroup size="lg">
                      <Form.Control
                        // ref={inputRef}
                        aria-label="Large"
                        aria-describedby="inputGroup-sizing-sm"
                        type="number"
                        value={drawnumber}
                        onChange={(e) => setDrawnumber(e.target.value)}
                        min="1"
                        max="90"
                      // disabled={numberInput ? false : true}
                      />
                      {!!drawnumber && drawnumber.length > 0 && (
                        <Button variant="warning" type="submit" hidden>
                          Submit
                        </Button>
                      )}
                    </InputGroup>
                  </form>}
                </>
              )}
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default TambolaGameView;
