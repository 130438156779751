import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../Components/Header";
import {Row, Table, Col, Form, Button} from "react-bootstrap";
import { addTambolaGameByAdmin, addTambolaGameBySubAdmin, getCategories, getOptionSubAdmin, getTambolaReward, tambolaFutureGames, upcommingGames, updateSubadminTambolaFutureGame, updateTambolaFutureGame } from "../../../reducers/commonReducer";
import SideBaar from "../../../Components/SideBaar";
import moment from 'moment';
import ActionTambolaGame from "./ActionTambolaGame";
import AutoFillDate from "../../../AutoFillDate";
import ActionTambolaGameSubadmin from "./ActionTambolaGameSubadmin";

const TambolaFutureGamesList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [startDate, setstartDate] = useState(moment().format('YYYY-MM-DDT00:00:00'));
  const [endDate, setendDate] = useState(moment().add(7, 'days').format('YYYY-MM-DDT23:59:59'));
  const [category, setCategory] = useState();
  const [subadmin, setSubadmin] = useState();
  const [gameid, setGameid] = useState();
  const [day, setDay] = useState();
  // const [currentPage, setCurrentPage] = useState(1);
  const queryParameters = new URLSearchParams(window.location.search);
  const subadminId = queryParameters.get('subadminId');
  let width = window.innerWidth;

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    } 
    dispatch(getCategories());
    if(subadminId){
      dispatch(tambolaFutureGames({subadminId, end:endDate, start:startDate,}));
    } else{
      dispatch(tambolaFutureGames({end:endDate, start:startDate,}));
    }
    if(localStorage.getItem('userRole') === "2"){
      dispatch(upcommingGames());
      // dispatch(tambolaFutureGames({subadminId}));
    }
    if(localStorage.getItem("userRole") === "1"){
      dispatch(getOptionSubAdmin());
      // dispatch(tambolaFutureGames({subadminId}));
    }
    dispatch(getTambolaReward())
  }, [dispatch, navigate, subadminId]);
  const { tambolaFutureGamesList, getCategoriesList, getOptionSubAdminList } = useSelector((state) => state.commonReducer);
  let currentTime = new Date();
  currentTime.setMinutes(currentTime.getMinutes() + 5);

  const handleSubmit = (e) => {
      dispatch(tambolaFutureGames({
        end:endDate, start:startDate, category, gameid, day, subadmin
     }))
   
  }
  
  return (
    <>
      <Row>  
      <Col md={2} className="p-0"> 
      <SideBaar/>
      </Col> 
      <Col md={10}>
      <Header />
      <div className="container">
        <Row className={`"mt-4 user-table" ${width > 500 ? `mb-2` : ``}`}>
          <Col md={6}>
            <h3>Games</h3>
          </Col>
          <Col md={6}>
          {((localStorage.getItem('userRole') === "1") || (localStorage.getItem('userRole') === "5")) && <ActionTambolaGame title={'Add'} btntitle={"Add"} btnFloat={"float-right"} apiFunction={addTambolaGameByAdmin}/>} 
          {(localStorage.getItem('userRole') === "2") && <ActionTambolaGameSubadmin title={'Add'} btnFloat={"float-right"} api={addTambolaGameBySubAdmin}/>} 
          </Col>
        </Row>
        <hr className="m-0"/>
        <Row className="mb-2 user-table">
          <Col md={2} sm={6} xs={6}>
            <label>
              <b>Start Date</b>
            </label>
            <Form.Control
              type="datetime-local"
              placeholder="Enter Date"
              step="1"
              value={startDate}
              onChange={(e) => setstartDate(e.target.value)}
            />
          </Col>
          <Col md={2} sm={6} xs={6}>
            <label>
              <b>End Date</b>
            </label>
            <Form.Control
              type="datetime-local"
              placeholder="Enter Date"
              step="1"
              value={endDate}
              onChange={(e) => setendDate(e.target.value)}
            />
          </Col>
          <Col md={2} sm={6} xs={6}>
            <AutoFillDate setendDate={setendDate} setstartDate={setstartDate} day={day} setDay={setDay}/>
          </Col>
          <Col md={1} sm={6} xs={6}>
            <label>
              <b>GameId</b>
            </label>
            <Form.Control
              type="text"
              className="px-1"
              step="1"
              value={gameid}
              onChange={(e) => setGameid(e.target.value)}
            />
          </Col>
          <Col md={2} sm={12} xs={12}>
            <label>
              <b>Category</b>
            </label>
            <Form.Select value={category} onChange={(e) => setCategory(e.target.value)} aria-label="Default select example">
              <option value="">Select Category</option>
              {
                !!getCategoriesList && getCategoriesList?.map((data, index) => <option key={index} value={data}>{data}</option>)
              }
            </Form.Select>
          </Col>
          <Col md={2}>
            {
               localStorage.getItem("userRole") === "1" && <>
                <label>
                  <b>SubAdmin</b>
                </label>
                <Form.Select value={subadmin} onChange={(e) => setSubadmin(e.target.value)} aria-label="Default select example">
                  <option value="">Select SubAdmin</option>
                  {
                    !!getOptionSubAdminList && getOptionSubAdminList?.map((data, index) => <option key={index} value={data?.id}>{data?.username}</option>)
                  }
                </Form.Select>
               </>
            }
          </Col>
          <Col md={1}>
            <Button className="mt-4 w-50 p-1" variant="success" type="submit" onClick={handleSubmit}>
            <i className="bi bi-search"></i>
            </Button>
            <Button className="mt-4 w-50 p-1" variant="danger" type="submit" onClick={() => window.location.reload(true)}>
            <i className="bi bi-arrow-clockwise"></i>
            </Button>
          </Col>
        </Row>
        <div className="overflow-auto">
          <Table striped bordered hover size="sm" className="overflow-scroll">
            <thead>
              <tr>
                <th>SrNo.</th>
                <th>Game</th>
                {/* <th>Name</th> */}
                {/* <th>StartDate</th> */}
                <th>Loop</th>
                <th>Price</th>
                <th>Set</th>
                <th>TotalTickets</th>
                <th>Booked</th>
                <th>Rewards</th>
                {/* <th>Ticket</th> */}
                <th>Status</th>
                <th>PlacedBet</th>
              </tr>
            </thead>
            <tbody> 
              {!!tambolaFutureGamesList &&
                tambolaFutureGamesList?.map((data, index) => {
                  let timeDiff = moment(data?.start)?.diff(moment(), "minutes");
                  return (
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td><b>ID:</b> {data?.idd}<br/>
                      <b>Name:</b> {data?.name}<br/>
                      <b>Start:</b> {data?.start ? moment(data?.start).format('MMM Do YY, h:mm a') : ''}<br/>
                      {data?.autoMatic && <b className="text-info">AutoMatic</b>}
                      </td>
                      {/* <td>{data?.name}</td> */}
                      {/* <td>{data?.start ? moment(data?.start).format('MMM Do YY, h:mm a') : ''}</td> */}
                      <td>{data?.loop}</td>
                      <td>{data?.ticketPrice}</td>
                      <td>{(((localStorage.getItem("website")?.length > 0)) && localStorage.getItem("userRole") === "2") ? 1 : data?.ticketSet}</td>


                      <td>{(!data?.end && ((localStorage.getItem("userRole") !== "1"))) ? <Link to={`/tambola-ticket-booking?gameHistory=${data?.gameHistory}&ticketSet=${data?.ticketSet}`}>
                            {localStorage.getItem("website")?.length > 0 ? data?.boxes : parseInt(data?.boxes / data?.ticketSet)}
                            <i className="bi bi-arrow-right-short"></i>
                            </Link> : (localStorage.getItem("website")?.length > 0 ? data?.boxes : parseInt(data?.boxes / data?.ticketSet))
                        }</td>

                        <td>{(localStorage.getItem("appSlug")?.length > 0) ? (data?.totalBookedTicket / data?.ticketSet) : data?.totalBookedTicket}</td>  
                      {/* <td>{(localStorage.getItem("appSlug")?.length > 0) ? data?.totalBookedTicket : data?.totalBookedTicket}</td> */}
                      <td>{data?.rewards.map((value, rewardIndex) => {
                            return <span key={rewardIndex} className="mb-0 p-18"><b>{value._id?.name}</b> = {value.prize}<b>,</b> </span>
                        })}</td>
                      {/* <td><Button disabled = {(localStorage.getItem("userRole") === "5") ? true : false} className="py-0 px-1" variant="info" onClick={() => navigate(`/game-tickets?userid=${data?.id}`)}>Details ??</Button></td> */}
                      <td>{data?.status ? (
                            <span className="text-success">
                            <b>Active</b>
                            </span>
                        ) : (
                            <span className="text-danger">
                            <b>Inactive</b>
                            </span>
                        )}</td>
                      <td>
                      {/* moment() < moment(data?.start) && */}
                        {
                            (!data?.end && ((localStorage.getItem('userRole') === "1") || ((localStorage.getItem('userRole') === "5") && (!data?.autoMatic)) || (localStorage.getItem('userRole') === "2"))) && (
                                <ActionTambolaGame
                                  title={'Edit'}
                                  eboxes={data?.boxes}
                                  ename={data?.name}
                                  eloop={data?.loop}
                                  ejackpotPrice={data?.jackpotPrice}
                                  erewards={data?.rewards}
                                  estart={data?.start}
                                  eticketPrice={data?.ticketPrice}
                                  eticketSet={data?.ticketSet}
                                  evideoid={data?.videoid}
                                  apiFunction={((localStorage.getItem('userRole') === "1") || (localStorage.getItem('userRole') === "5")) ? updateTambolaFutureGame : updateSubadminTambolaFutureGame}
                                  eid={data?.id}
                                  ecategory={data?.category}
                                  eunsoldwinner={data?.unsoldwinner}
                                  eshowMessage={data?.showMessage}
                                  eshowOnTop={data?.showOnTop}
                                  eautoMatic={data?.autoMatic}
                                />
                              )
                        }
                        {
                          <Link to={`/tambola?gameid=${data?.gameHistory}&startGame=${data?.start}&automatic=${data?.autoMatic}&start=${data?.start}&endStatus=${data?.end ? 1 : 0}&userid=${data?.autoMatic ? data?.userid : ''}`}>
                          {(((currentTime > new Date(data?.start)) && data?.start !== null)) ? (
                            <Button className="py-0 px-1" variant="warning">Live</Button>
                          ) : (
                            <Button className="py-0 px-1" variant="info"><i className="bi bi-eye-fill"></i></Button>
                          )}
                        </Link>
                        }
                        {
                            localStorage.getItem('userRole') === "1" && !data?.end && (
                            <Button
                              className="m-1 py-0 px-1"
                              variant="secondary"
                              onClick={async () => {
                                const userConfirmed = window.confirm('Are you sure the game is completed?');
                                if (userConfirmed) {
                                    await dispatch(updateTambolaFutureGame({
                                        id:data?.id,
                                        end:1
                                    }))
                                    await dispatch(tambolaFutureGames());
                                }
                              }}
                            >
                              Completed
                            </Button>
                          )
                        }
                         {/* {
                            ((localStorage.getItem('userRole') === "1") && data?.autoMatic && timeDiff > 5) && (
                            <Button
                              className="m-1 py-0 px-1"
                              variant="warning"
                              onClick={async () => {
                                const userConfirmed = window.confirm(`Are you sure the game is ${data?.hiddenNumbers?.length ? 'Re-Generate' : 'Generate'}?`);
                                if (userConfirmed) {
                                    await dispatch(updateTambolaFutureGame({
                                        id : data?.id,
                                        genratenumber : 1
                                    }))
                                    await dispatch(tambolaFutureGames());
                                }
                              }}
                            >
                              {data?.hiddenNumbers?.length ? 'Re-Generate' : 'Generate'}
                            </Button>
                          )
                        } */}
                        {/* {data?.status && (
                            <Button variant="danger"
                                className="m-1 py-0 px-1"
                                onClick={async() => {
                                if (window.confirm("Are you sure to Delete!")) {
                                await dispatch(updateTambolaFutureGame({
                                    id:data?.id,
                                    softdelete:1
                                }))
                                await dispatch(tambolaFutureGames());
                                } 
                            }}
                            >
                            <i className="bi bi-trash3-fill"></i>
                            </Button>
                        )} */}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          {/* {
            !!gameRecordsBlank && gameRecordsBlank?.length < 1 ? <h3 className="text-center text-danger">Not Found</h3> : 
            <Button onClick={() => {
              if(localStorage.getItem('userRole') === "2"){
                dispatch(upcommingGames({currentPage:currentPage + 1, end:endDate, start:startDate, day}));
              }else{
                dispatch(tambolaFutureGames({currentPage:currentPage + 1, end:endDate, start:startDate, day, subadminId}));
              }
              setCurrentPage(currentPage + 1)
            }} className="w-100">Load More</Button>
          } */}
        </div>
      </div>
      </Col>
      </Row>
    </>
  );
};

export default TambolaFutureGamesList;
